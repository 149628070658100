<template>
  <Day day="04.12.2024">
    <div slot="page-content">
      <h3>Partners in Crime</h3>
      <p>
        <img src="@/assets/day4.png"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day04',
  components: {
    Day
  }
}
</script>
