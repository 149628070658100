<template>
  <Day day="06.12.2024">
    <div slot="page-content">
      <h3>Da wurde wohl ein Plan gefasst 🤔</h3>
      <p>
      <img src="@/assets/day6.png"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day06',
  components: {
    Day
  }
}
</script>
