<template>
  <div>
    <h1>Frohe Weihnachten</h1>
    <div class="cards-wrapper">
      <ul class="cards">
        <li
            class="card"
            v-for="i in days"
            :key="i"
            :class="{'visited': visited(i)}"
        >
          <div class="link" v-on:click="toDay(i)">
            {{ i }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as Cookies from "tiny-cookie";
import Moment from "moment/moment";

export default {
  name: 'Calendar',
  data() {
    const days = Array.from({length: 24}, (_, i) => i + 1);

    for (let i = days.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Zufälliger Index
      [days[i], days[j]] = [days[j], days[i]]; // Tausche die Elemente
    }

    return {days}
  },
  methods: {
    toDay: function (i) {
      const now = Moment();
      const dayDate = new Moment('2024-12-01', 'YYYY-MM-DD').add(i - 1, 'days');

      if (now >= dayDate) {
        const expiryDate = new Date()
        expiryDate.setMonth(expiryDate.getMonth() + 2)

        const visitedDaysValue = Cookies.getCookie('VISITED_DAYS');
        let visitedDays = !visitedDaysValue ? [] : JSON.parse(visitedDaysValue);
        if (visitedDays.indexOf(i) === -1) {
          visitedDays.push(i);
        }

        Cookies.setCookie('VISITED_DAYS', JSON.stringify(visitedDays), {expires: expiryDate})
      }
      this.$router.push('/day/' + i)
    },
    visited: function (i) {
      const visitedDaysValue = Cookies.getCookie('VISITED_DAYS');

      if (!visitedDaysValue) {
        return false;
      }

      const visitedDays = JSON.parse(visitedDaysValue);
      return visitedDays.indexOf(i) > -1;
    }
  }
}
</script>

<style lang="scss" scoped>
.cards-wrapper {
  width: 1200px;
  height: 600px;
  margin: 0 auto;
  max-width: 100%;
}

.cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media screen and (max-width: 385px) {
    justify-content: center;
  }
}

.card {
  width: 100px;
  height: 100px;

  border-radius: 25px;

  margin: 35px;

  background: rgba(#000, 0.1);
  border: 1px solid #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    background: rgba(#000, 0.3);
    box-shadow: 0 0 20px rgba(#fff, 0.3);
  }

  &.visited {
    background: #989898;

    .link {
      color: #353535;
    }
  }

  .link {
    outline: 0;
    color: #fff;
    text-decoration: none;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active,
    &:focus {
      border: none;
    }
  }

  div {
    font-family: 'Handlee', cursive;
    font-size: 52px;
    font-weight: bold;
    padding-top: 5px;

    width: 100%;
    height: 100%;
  }
}
</style>
