<template>
  <Day day="21.12.2024">
    <div slot="page-content">
      <h3>Muuuh 🐄</h3>
      <p>
        <img src="@/assets/day21.jpeg"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day21',
  components: {
    Day
  }
}
</script>
