<template>
  <Day day="02.12.2024">
    <div slot="page-content">
      <h3>Unser kleiner Engel</h3>
      <p>
        <img src="@/assets/day2.jpeg"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day02',
  components: {
    Day
  }
}
</script>
