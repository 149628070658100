<template>
  <Day day="13.12.2024">
    <div slot="page-content">
      <h3>Jack in the Box 🤡</h3>
      <p>
        <img src="@/assets/day13.jpeg"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day13',
  components: {
    Day
  }
}
</script>
