<template>
  <Day day="18.12.2024">
    <div slot="page-content">
      <h3>Kaja, die Nasen-Terminatorin</h3>
      <p>
        <video autoplay plays-inline>
          <source src="@/assets/day18.mp4" type="video/mp4">
        </video>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day18',
  components: {
    Day
  }
}
</script>
