<template>
  <Day day="22.12.2024">
    <div slot="page-content">
      <h3>Übung macht die Meisterin 😍</h3>
      <p>
        <video autoplay muted plays-inline>
          <source src="@/assets/day22.mp4" type="video/mp4">
        </video>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day22',
  components: {
    Day
  }
}
</script>
