<template>
  <Day day="24.12.2020">
    <div slot="page-content">
      <h3>Ich liebe dich über alles 😘<br/>Frohe Weihnachten</h3>
      <p>
        <iframe src="https://drive.google.com/file/d/110smyDRhttgagQVDFfVRSsQQ84rAyhJQ/preview" width="640" height="480"
                allow="autoplay"
        ></iframe>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day24',
  components: {
    Day
  }
}
</script>
