<template>
  <Day day="14.12.2024">
    <div slot="page-content">
      <h3>Play Time</h3>
      <p>
        <img src="@/assets/day14.jpeg"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day14',
  components: {
    Day
  }
}
</script>
