<template>
  <Day day="11.12.2024">
    <div slot="page-content">
      <h3>Cool Daddy? No, cool Baby! 😎</h3>
      <p>
        <img src="@/assets/day11.jpeg"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day11',
  components: {
    Day
  }
}
</script>
