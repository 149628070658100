<template>
  <Day day="23.12.2024">
    <div slot="page-content">
      <h3>Der Abbruch kann beginnen 🙈</h3>
      <p>
        <img src="@/assets/day23.jpeg"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day23',
  components: {
    Day
  }
}
</script>
