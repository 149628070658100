<template>
  <Day day="10.12.2024">
    <div slot="page-content">
      <h3>Rare Family Time</h3>
      <p>
        <img src="@/assets/day10.jpeg"/>
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day10',
  components: {
    Day
  }
}
</script>
